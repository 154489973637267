#externalPrintPackageBanner
    padding 15px

    .actions
        display flex
        flex-direction column
        justify-content space-between

        @media (min-width: 576px)
            flex-direction row

    .link
        :hover
            color #b82f81

    .cancel
        color #707070
        :hover
            color #707070

    .link, .cancel
        cursor pointer
        > *
            padding-right 10px

    .loading-container
        display flex
        justify-content center