#reportsDashboardContainer
    .search
        margin-bottom 30px
    .search .form-group {
        margin: 0 15px;
    }
    .search .location-picker button, .search .input-group {
        margin-top 10px
        width: 250px;
    }
    @media (max-width: 1199px) {
        .search .location-picker button, .search .input-group {
            width: 200px;
        }
    }
    @media (max-width: 991px) {
        .search .location-picker button, .search .input-group {
            width: 140px;
        }
    }
    @media (max-width: 767px) {
        .search {
            text-align: left;
            padding: 30px;
        }

        .search .location-picker button, .search .input-group {
            width: 100%;
            margin-bottom: 10px;
        }

        .search button {
            float: right;
        }

        .search .input-group-btn button {
            margin-bottom: 10px;
        }
    }
    .search .input-group-btn
        width 1%

    .search.form-inline input
        margin-right initial

    table.text-center th,table.text-center td
        text-align center

    table.text-center th.text-left,table.text-center td.text-left
        // Required to override the above style by specificity
        text-align left
    .panel-heading, .panel-body 
        padding-left 0
        padding-right 0
    .panel-padding 
        padding-left 15px
        padding-right 15px


.report-container
    padding-left 0
    padding-right 0

.report-controls
    display flex
    align-items flex-start
    justify-content space-between
    flex-wrap wrap
    .search .form-group
        margin 0px 15px
    .print-button
        padding 12px 20px
    .btn-text
        padding 17px 0px
        .fa
            display none
    .report-buttons
        padding-top 26px
    .search-button
        padding-top 34px

.report-locations
    ul
        display flex
        justify-content flex-start
        flex-wrap wrap
        padding-left 0
        padding-bottom 2px
        li
            list-style: none


/* Reporting styles */
.report
    p
        color: #000;
    .panel-heading
        padding-top: 15px;
        display flex
        align-items center
        img
            margin-right 8px
        small
            color: #666;
            font-size: 12px;

.facebook-top-posts
    .sub-heading
        padding-bottom: 4px
        margin: 0px 16px 8px

    .facebook-top-posts-row
        margin-bottom 30px
        
    .top-post-h
        font-size 20px
        font-weight 500
        margin-bottom 6px

    .expand-button
        margin-right: 16px

    .top-post-type-icon
        font-weight 500
        color: black
        margin 12px 0px

    .facebook-top-post
        max-width: 130px
        max-height: 130px
        display: block

.report-md {
    font-size: 24px;
}

striking-color = #c92884;

.text-striking
    color striking-color

a.text-striking, a .text-striking
    &:hover, &:focus, &:active
        color darken(striking-color, 20%)
        text-decoration underline

.text-success-bright {
    color: #5fb760;
}

.report .report-fade {
    color: #999;
}

.report-date {
    margin-top: 0px;
    text-align: right;
}

.report-buttons
    margin-left auto

.report-lg {
    font-size: 30px;
}

.ds-report-panel
    h5
        font-weight 500

.chart-legend-item
    display flex
    flex-direction row
    align-items center
    .legend-dot
        height 12px
        width 12px
        border-radius 50%  
        margin-right 8px

// This is getting overridden by another rule for .space-right in site.styl, so I've commented it out for now. - LM 31/08/18
//.space-right {
//    margin-right: 40px;
//}

.report h6 {
    margin-bottom: 4px;
}

h5,
h6 {
    font-size: 16px;
}

.btn-outline {
    border: 1px #ccc solid;
}

.report-ad-date {
    margin-top: 8px;
}

.report-summary
    margin-bottom 10px

    .edit-icon
        color #999
        font-size 0.9em

.report-summary .edit-icon:hover
    color #4c87c6

.read-more-container
    display inline

.read-more-content-container
    margin-top 10px
    margin-bottom 25px
    p
        line-height 1.5em

    &.collapsed
        display none

.reports-table-wrap
    overflow-x auto

.reports-table-child-row
    &> td:first-of-type
        padding-left 27px


.report-table-row-footer
    &:hover
        color inherit

table.table.reports-table
    margin-bottom 5px

    thead
        th
            .fa-sort
                &:hover
                    color #666

            .fa-sort, .fa-caret-down, .fa-caret-up
                width 14px
                padding-left 5px

    tr.report-table-row-footer
        td
            border-top none

    tbody:hover > tr > td
        background-color #f5f5f5

    tbody+tbody
        border-top none

    .table-cell-content-container
        display flex
        flex-direction row

    .table-cell-content
        flex-grow 1

    .report-table-row-indent
        display inline-block
        visibility hidden

    .report-table-row-expander
        display inline-block

.panel-fitware-member-engager
    .description-line
        color #999
        font-size 11px

.panel-google-adwords
    .fa.device
        font-size 34px

    .description-line
        color #999
        font-size 11px

    .report-section-options
        .btn-group
            vertical-align top

            &:last-of-type
                margin-left 15px

    .currency-note
        display inline-block
        margin-top 10px

    @media screen
        .google-adwords-report-table
            display none

        .google-adwords-report-table-title
            display none

    &[data-selected-google-adwords-report-type="brandAwareness"][data-selected-google-adwords-report-by-type="ad"]
        .google-adwords-report-table[data-google-adwords-report-type="brandAwareness"][data-google-adwords-report-by-type="ad"]
            display block

    &[data-selected-google-adwords-report-type="brandAwareness"][data-selected-google-adwords-report-by-type="keyword"]
        .google-adwords-report-table[data-google-adwords-report-type="brandAwareness"][data-google-adwords-report-by-type="keyword"]
            display block

    &[data-selected-google-adwords-report-type="conversions"][data-selected-google-adwords-report-by-type="ad"]
        .google-adwords-report-table[data-google-adwords-report-type="conversions"][data-google-adwords-report-by-type="ad"]
            display block

    &[data-selected-google-adwords-report-type="conversions"][data-selected-google-adwords-report-by-type="keyword"]
        .google-adwords-report-table[data-google-adwords-report-type="conversions"][data-google-adwords-report-by-type="keyword"]
            display block

    &[data-selected-google-adwords-report-type="recommendations"]
        .google-adwords-report-table[data-google-adwords-report-type="recommendations"]
            display block

    .google-adwords-report-table[data-google-adwords-report-type="recommendations"]
        .no-marketloss
                background #f5f5f5
                text-align center

        .report-table-row-footer
            @media print
                display none

            .spend-slider
                padding 0 100px
                overflow hidden
                margin 5px 0 10px 0
                padding-bottom: 3em
                ul
                    margin 0
                    padding 0
                    list-style none
                    position relative

                    li
                        width 200px

                        p
                            margin-bottom 0
                            text-align center
                            color #666

                        &.current-spend
                            float left
                            margin-left -100px

                        &.max-spend
                            float right
                            margin-right -100px

                        &.recommended-spend
                            position absolute
                            width 200px
                            margin-left -100px
                            top: 60px
                            cursor: pointer

                .spend-slider-control
                    margin-top 5px

                    input[type="range"]
                        width 100%

            .potential-spend-edit
                margin-right 10px
                max-width 120px

                input
                    min-width 80px
                    text-align right
            .adjust-spend
                @media (min-width: 1200px)
                    margin-top: 35px;
                margin-top: 22px;
                padding: 0;
            .daily-budget
                width: 100%
                margin-top: 5px
                color: #999