.planner-event
    .recurring-end-on
        margin-right: 0!important
        @media (min-width: 768px)
            width: 8.5em!important

    .recurring-event-form-item
        margin: 10px 0;

        .recurring-event-form-label
            width: 40px
            display: inline-block
            font-weight: normal