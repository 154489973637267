.map-warper
    margin: auto

    .map-view
        height: 100%
        width: 100%


.pac-container
    z-index: 2000;
    &:after
        content: "";
        padding: 1px 1px 1px 0;
        height: 16px;
        text-align: right;
        display: block;
        background-image: url(//maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: 104px 16px

.hdpi .pac-container
    &:after
        background-image: url(//maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png)

.payment-note
    padding: 8px;
    border-radius: 3px;

.form-group
    &.map-payment
        padding-left: 10px;