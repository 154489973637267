.location-picker
    width 100%

    button.btn
        width 100%
        overflow-x hidden
        box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.2)
        padding-right 25px
        .title
            overflow hidden
            //width 20em
            width 100%
            text-align left

    .caret
        margin-left 0.5em

    .location-picker-list
        width 100%
        max-height 20em
        overflow-y auto
        overflow-x hidden
        white-space normal
        margin 0
        list-style none

        a
            text-decoration none

        .browse
            .text
                overflow hidden
                width 100%
                text-align left
            a
                padding-left 20px
                padding-right 20px
            &:hover
                a
                    background #6b30fa
                    color #FFF

        .dropdown-searchbox
            padding-left 0.5em
            padding-right 0.5em
            margin-bottom 0.5em
            top: 0;
            position: sticky
            background #FFF
            input
                width 100%

        .dropdown-menu
            display inherit
            position inherit
            border 0
            float none
            -webkit-box-shadow none
            box-shadow none
            white-space normal
            padding 0

        .client-title
            padding-left 0.66em
            color #AAA
            white-space normal
            &:hover
                overflow-x auto
                text-decoration none

        .location-entry
            cursor pointer

        .location-entry > a
            white-space normal
            word-break break-word

        li.location-entry:hover:not(.disabled)
            a
                overflow-x auto
                background #6b30fa
                color #FFF

.location-picker.open
    button.btn.btn-default
        border-bottom-left-radius 0
        border-bottom-right-radius 0

    ul.location-picker-list
        border-top none
        border-top-left-radius 0
        border-top-right-radius 0
        padding: 0 0 5px 0

.multiple-location-picker>.dropdown-menu
    // Hack to use Bootstrap dropdown menu styling without being an actual dropdown menu.
    float none
    -webkit-box-shadow none
    box-shadow none
    display block
    position inherit
    height 20em
    padding: 0 0 5px 0

    .dropdown-searchbox
        z-index inherit

    .client-title:not(.disabled)
        color #333

        &:hover
            overflow-x auto
            background #6b30fa
            color #FFF

    .checkbox
        margin-top 0
        margin-bottom 0

    .location-entry.disabled
        cursor default

        .disabled-entry
            min-height 20px
            position relative
            display block
            padding 2px 40px
            clear both
            font-weight 400
            color #AAA
            margin-top 0
            margin-bottom 0
            white-space normal
            word-break break-word

            label
                padding-left 20px
                margin-bottom 0
                font-weight 400

.additional-locations-list
    max-height 17em
    overflow-y scroll
.cart-location-holder
    display flex
    justify-content flex-end
    align-items flex-start

    .shopping-cart
        flex 1

    .location-picker-holder
        width 100%
