.table-controls
    display flex
    flex-wrap wrap

    .left-controls
        flex 1
        justify-content left

    .page-controls
        flex 2
        display flex
        justify-content center

        & > *
            display flex
            flex-wrap nowrap
            align-items flex-start

        .table-page-indicator
            padding 0 0.5em
            white-space nowrap
            justify-content center

            .form-group
                margin-bottom 0

                .page-input
                    width 4em
                    text-align right
                    display inline-block

        @media(max-width: 480px)
            flex-flow row wrap

            .table-page-indicator
                flex 2 1 100%
                order 1
                margin-top 10px

    .per-page-controls
        flex 1
        display flex
        justify-content flex-end

        .no-input-wrap
            display: inline-block;
            width: auto;
            vertical-align: middle;

    @media(max-width: 480px)
        flex-direction column

        .left-controls
            order 3
            justify-content center
            text-align center

        .page-controls
            order 1
            justify-content center

        .per-page-controls
            order 2
            justify-content center

// This fits the controls closer to the bottom of the table, and leaves a gap underneath.
.table-controls-bottom
    margin-top -10px
    margin-bottom 20px

    @media(max-width: 480px)
        .table-controls > div + div
            margin-bottom 10px

        flex-direction column

.table-controls-top
    margin-bottom 10px

    @media(max-width: 480px)
        .table-controls > div + div
            margin-top 10px

        .left-controls
            order 1

        .page-controls
            order 3
            flex-flow row wrap

            .table-page-indicator
                order -1
                margin-bottom 10px
                margin-top 0

        .per-page-controls
            order 2