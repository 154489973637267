color1 = #6b30fa
$calendar-ready-color = #ff8400
$thumbnail-size = 200px
$thumbnail-size-small = 110px

#builderTemplateGalleryContainer, #memberEngagerContainer

    .gallery-search-bar
        padding-bottom 1em

    .search
        margin-bottom 1em
        align-items center

        .keyword-search
            width 100%

            input
                width 100%

            .input-group-btn
                width 39px

        .form-group
            width 100%
            margin-bottom 0

            .fake-button.loading-placeholder
                width 100%
                font-size 14px

            .dropdown-menu
                width 100%

            .btn-group
                display inherit // override bootstrap's inline-block, so flexbox can work
                width 100%

                &.gallery-format-button-group
                    display flex // HACK to get the dropdown menu to appear under the button. TODO: determine the root cause

                button
                    width 100%
                    text-align left
                    white-space nowrap
                    overflow hidden
                    text-overflow ellipsis
                    font-family GalanoGrotesque, sans-serif

                    .caret
                        position absolute
                        right 12px
                        top 50%
                        margin-top -2px
                        vertical-align middle

            .category-criteria-button-container, .editable-fields-picker-button-container
                min-width 10em // failing to set this high enough will cause angular-multiselect-dropdown to go into an infinite digest!

                #deselectAll
                    cursor pointer

                    .glyphicon-remove::before // Replace the "remove" icon, because crosses are mean
                        content "\e067"

                button
                    min-width 10em // failing to set this high enough will cause angular-multiselect-dropdown to go into an infinite digest!
                    width 100% // Fill to fit the cell in the flexbox

                // Disabled items need to be styled, and the checkboxes hidden
                a[disabled]
                    opacity 0.5

                    input.checkboxInput
                        visibility hidden

            .format-picker-button
                width 100%


            .format-picker-container
                max-height 40em
                min-width 100%
                overflow-y auto

                @media (min-width: 992px)
                    min-width 200%

                a
                    color #262626
                    &:hover
                        background-color #f5f5f5
                    &:focus
                        background-color #f5f5f5

                .checkbox
                    width 100%

                    label
                        width 100%

        .format-tree-format
            .checkbox
                label
                    display block
                    margin-left 20px
                    input
                        float left
                        margin-left -20px

.bottom-right-elements
    display: flex
    justify-content: end
    .show-old-email-templates-switch
        margin-top: 5px
        label
            font-weight: 400
            margin-right: 10px

.caption-title
    font-size 16px

.template-group
    display block
    margin-bottom 40px

    + .template-group
        margin-top 1em

    .template-group--label
        display block
        a
            color #000000
            text-decoration none
            cursor pointer

    .template-group--templates
        overflow-y auto
        overflow-x scroll
        padding-top: 2px;
        -webkit-overflow-scrolling: touch;
        display flex
        align-items flex-start

        &::-webkit-scrollbar
            display none

        &.template-search-results
            flex-wrap wrap

    .template-group--templates-container
        position relative
        // cursor pointer
        min-height $thumbnail-size

        .template-group--templates--template
            flex 0 0 $thumbnail-size

        $go-left-right-size = 85px
        $go-left-right-size-medium = 40px
        $go-left-right-size-small = 20px

        .template-group--go-left-container, .template-group--go-right-container
            position absolute
            align-self center
            color color1
            top 0
            width $go-left-right-size
            display flex
            flex-direction row
            align-items center
            cursor: pointer
            opacity 0
            z-index 10
            height 100%

            .template-group--go-right--inner, .template-group--go-left--inner
                display flex
                justify-content center
                align-items center
                flex-direction column
                height 48px
                width 48px
                background white
                border 3px solid color1
                border-radius 50%
                color color1


            .template-group--go-right--inner .fal
                padding-left 2px
            .template-group--go-left--inner .fal
                padding-right 2px

        .template-group--go-left-container
            left 0
            background linear-gradient(270deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%, white)

        .template-group--go-right-container
            right 0
            background linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 100%, white)

        @media (max-width: 991px)
            .template-group--go-left-container, .template-group--go-right-container
                width $go-left-right-size-medium

                .template-group--go-right--inner
                    // padding-left 0

                .template-group--go-left--inner
                    padding-right 0

        @media (max-width: 767px)
            .template-group--go-left-container, .template-group--go-right-container
                width $go-left-right-size-small

        @media (max-width: 479px)
            .template-group--go-left-container, .template-group--go-right-container
                display none

    .template-group--templates-container:hover

        .template-group--go-left-container, .template-group--go-right-container
            opacity 1

.card.raise-on-focus.template-thumbnail
    padding 0.2em

.template-thumbnail
    margin-bottom 0
    position: relative

    .draft-icon
        position: absolute
        width: 65px
        z-index: 1

    .mobile-icon
        position: absolute
        height: 65px
        width: 65px
        z-index: 1
        right: 3px
        top: 108px

    &.faded
        transition: 1s
        opacity 0.5

    .caption
        display flex
        padding 16px 0px 12px 0px
        margin-bottom 4px
        border-bottom 1px solid #6b30fa

        h4
            color #333
            margin 0px
            flex-grow 1
            max-width: 170px
            font-size 14px
            height 36px
            overflow hidden
            &.line-clamp
                display -webkit-box
                -webkit-line-clamp 2
                -webkit-box-orient vertical

        .template-menu-container
            position relative

        .template-menu-icon
            color #BBB
            padding 0.5em 0.3em 0.5em 0.5em

            &:hover
                text-decoration none
                color #444

    .template-footer
        display: flex
        align-items: center
    
    .icon
        display inline-block
        color color1
        font-size 1.5em
        margin-right 10px

    .template-draft-icon
        display: inline-flex
        margin-right: 0
        margin-left: auto
        
        > *
            display: inline-flex

    a img
        width auto
        max-height $thumbnail-size

.thumbnail-container
    background-color #F2F2F2
    height $thumbnail-size
    width $thumbnail-size
    display flex
    flex-direction initial
    align-items center
    position relative
    &.draft
        opacity: 0.6

    .multi-image-template-counter
        position: absolute
        bottom: 10px
        right: 10px

#builderTemplateGalleryFormatTree
    &>divider
        padding 0 20px

    .deselectAll
        padding 3px 20px
        display inline-block
        width 100%
        cursor pointer

        // Replace the "remove" icon, because crosses are mean
        .glyphicon-remove::before
            content "\e067"

    .format-tree-group
        padding 3px 20px

        &:hover
            background-color #f5f5f5
        &:focus
            background-color #f5f5f5

        h4
            margin 0

    .format-tree-formats
        margin-left 1.35em

    .checkbox
            label
                line-height: 16px;
                input[type=checkbox]
                    margin-top: 2px;

#plannersReadyToBuildBar
    height 48px
    border-radius 6px
    border solid 2px #f5cf47
    background-color #ffffff
    color black
    padding 10px

    >.btn
        color color1
        i
            display none


@media (max-width: 991px)
    .template-group
        .template-group--templates-container
            min-height $thumbnail-size-small

            .template-group--templates--template
                flex 0 0 $thumbnail-size-small

        .template-group--templates-container

            .template-group--go-left-container, .template-group--go-right-container

                // @media (max-width: 991px)
                //     .template-group--go-right--inner, .template-group--go-left--inner
                //         flex 0 0 $thumbnail-size-small

    .thumbnail-container
        height $thumbnail-size-small

    .template-thumbnail
        a img
            max-height $thumbnail-size-small

        .mobile-icon
            top: 48px