#skuGroupPrintProvidersTable
    button
        min-width: 70px

.payment-auth
    display flex

    > .fa
        display flex
        align-items center
        justify-content center
        min-width 25px
        padding-right 10px

    .fa-check
        color #5cb85c

    .fa-times
        color #c9302c