.profile-picture
    font-family tahoma
    font-size 45px
    text-shadow 3px 3px 4px rgba(0, 0, 0, .5)
    color white
    border-radius 4px
    overflow hidden
    width 64px
    height 64px
    position relative

    svg
        width 100%
        height 100%
        position absolute

        text
            text-anchor middle
            fill currentColor
            user-select none
            cursor default

    img
        width 100%
        height auto
        position absolute
