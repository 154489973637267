.navbar-brand
    margin-top 5px
    margin-right 20px

.navbar-collapse.collapse
    margin-top 5px

ul.nav.navbar-nav
    & > li > a > .badge
        // Prevent a border from appearing when the nav-item is hovered
        border-bottom none !important
        font-size 10px
        position relative
        top -10px
        font-weight normal

    &.navbar-right > li
        // Fix to prevent wrapping of right-side menu items on silly Safari
        @media (min-width 992px)
            float none
            display inline-block
            vertical-align middle

.navbar-inverse
    box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color #ffffff;
    border 0

    .navbar-nav>li>a
        font-family GalanoGrotesque, sans-serif
        font-size 16px
        font-weight 400
        font-stretch normal
        line-height 1.25
        letter-spacing normal
        color #000000
        &:hover, &:active, &:focus
            color #000000
            span
                border-bottom: 2px solid #FF4F4C

    .navbar-nav>.active>a, .navbar-nav>.active>a:hover, .navbar-nav>.active>a:focus
        color: #000
        background-color: transparent
        span
            border-bottom: 2px solid #FF4F4C

    li.dropdown
        li
            .fa
                width 24px
                text-align center
                color #1f2b37


/* Navbar height override */
.navbar
    min-height 68px
.navbar-nav
    margin-top: 4px
    min-height 30px

.nav
    a
        .profile-picture
            margin-left 7px
            margin-right 10px
            display inline-block
            border-radius 50%
            opacity .9

            width 16px
            height 16px
            // Use transform to make image little bigger without increasing height of navbar
            transform scale(2) translateY(1.4px)

            // @media (min-width 1200px)
            //     margin-left 5px
            //     width 16px
            //     height 16px
            //     transform scale(1.8) translateY(3px)

            text
                text-shadow 1px 1px 1px rgba(0, 0, 0, 0.5)
                cursor pointer

        .user-name
            margin-left 5px

            @media (min-width 992px)
                display none

        .caret
            margin-left 5px
            display: none

    a:hover
        .profile-picture
            opacity 1

.navbar-inverse .navbar-nav>.open>a, .navbar-inverse .navbar-nav>.open>a:hover, .navbar-inverse .navbar-nav>.open>a:focus
    background-color transparent
    color black

.navbar-nav > li > a
    line-height 30px

.navbar-nav > li > a
    &.badger-menu-item
        height 60px // Hacky fix for flex not taking up full height
        display flex
        align-items center
        padding-left 8px
        padding-right 8px
        color black

.badger-menu-item
    .badge
        background-color #c42a86
        margin-right 0.25em
        padding 2px 5px

.badgers-navbar
    margin-top 6px
    margin-bottom 0
    list-style none
    float right
    padding-left 0

    &>li>a>i.fa
        color #fff

    > li
        display inline-block

    li a
        position relative
        text-decoration none
        line-height 20px
        padding 10px

    .badger-menu-item
        display flex
        align-items center
        padding 10px 8px
        @media (max-width: 350px)
            padding 10px 4px



@media (max-width: 1199px)
    .navbar
        min-height 68px
    .navbar-nav > li > a
        line-height 20px
        &.badger-menu-item
            height 50px // Hacky fix for flex not taking up full height

/* Custom navigation collapse width */
@media (max-width: 991px) {
    .navbar-header {
        float: none;
        margin-top: 5px;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        // margin: 7.5px -15px;
    }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .navbar-nav>li>a>i.fa {
        text-align: center;
        min-width: 20px;
    }
    .navbar-text {
        float: none;
        margin: 15px 0;
    }
    /* since 3.1.0 */
    .navbar-collapse.collapse.in {
        display: block!important;
    }
    .collapsing {
        overflow: hidden!important;
    }
}

@media (max-width: 991px)
    .navbar-brand
        margin-top 0px
    .navbar-collapse.collapse
        overflow-y: auto!important;
    .navbar-nav
        .open .dropdown-menu
            position: static;
            float: none;
            width: auto;
            margin-top: 0;
            background-color: transparent;
            border: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
            a
                color: #black;
                i.fa
                    color: white;
            >li>a
                line-height: 20px;
            .divider
                background-color: #080808;
        &.account
            display: inline-block;
        a#profileDropdownToggle
            max-width: none;

@media (max-width: 768px)
    .navbar-inverse .navbar-nav
        .open .dropdown-menu>li
            >a
                color: black;
                &:active, &:focus, &:hover
                    color:black
                i.fa
                    color: white;
    .navbar-nav
        &.account
            display: block;
            width: auto;
.locale-flag
    margin-right 8px

a#profileDropdownToggle
    text-overflow ellipsis
    max-width 200px
    white-space nowrap
    overflow hidden
    color black
    &:hover, &:focus, &:active
        color black

.navbar.introjs-fixParent
    z-index: 1030!important; // Fix Intro.js screwing up the z-index of the navbar

// .navbar-inverse .navbar-nav>.open>a
//     &:hover, &:focus, &:active
//     color black

.navbar-inverse .navbar-toggle
    border-color white
    margin-top 14px
    &:hover, &:focus
        background-color white

.navbar-inverse .navbar-toggle .icon-bar
    background-color: black
    &:nth-child(3)
        background-color white

.navbar-toggle .icon-bar
    width 30px
    height 2px

.nav .account
    i
        display inline-block
