
.shopping-cart-order
    margin-bottom 95px

    input, select
        max-width: 90px
        &.ng-invalid-required
            border 1px solid #d80000
            outline 0
            -webkit-box-shadow inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(241,47,67,.6)
            box-shadow inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(241,47,67,.6)

    .print-provider
        margin-bottom 25px

    span, small
        display block

    small
        font-size 85%
        &.required, &.error
            color #d80000
            margin-top 0.25em
            font-weight 500

    .text-large
        font-size 1.15em
        color #000

    .entry
        background #fff
        border 1px solid #ddd
        border-bottom 0
        padding 15px
        display flex
        flex-direction column
        align-items center

        > *
            order 2
            text-align center
            padding 10px

        .location .shipping-address
            display flex
            align-items center
            justify-content center
            a
                font-size 15px
                margin-left 10px
                &:hover
                    .fa
                        color #b82f81
                &:focus
                    text-decoration none

        .location-quantity
            text-align center

        .quantity
            display flex
            justify-content center
            min-width: 150px

        .thumb
            display flex
            flex-direction column
            align-items center
            min-height 100px
            min-width 100px
            justify-content center
            border 1px solid #aaa
            padding 0
            margin 0
            img
                padding 1px
                max-width 100px
                max-height 100px

        .price
            text-align right
            .calculating
                color #ccc

        .actions
            min-width 50px
            width 100%
            order 1
            display flex
            justify-content flex-end
            button
                border 0
                background transparent
                font-size 1.275em
                color #aaa

    .shipping, .total
        .thumb
            min-height 60px
        .thumb, .actions, .location
            border none
            display none

    .total
        border-bottom 1px solid #ddd
        .quantity
            display none

    .stripe-payment
        margin-top 20px
        display flex
        flex-direction row-reverse

@media(min-width: 768px)
    .shopping-cart-order
        .entry
            display flex
            flex-direction row
            align-items center

            > *
                order 1
                text-align left
                display block
                padding 10px

            .thumb
                flex 3%
                margin-right 15px

            .details
                flex 25%

            .location
                flex 40%

                .shipping-address
                    justify-content flex-start

            .quantity
                flex 13%

            .price
                flex 15%

            .actions
                flex 2%
                order 2

        .shipping, .total
            .thumb, .actions, .location, .quantity
                display block

#shoppingCartBadger
    .fa-shopping-cart
        font-size 19px

#four51ShoppingCart
    height 34px
    display flex
    justify-content flex-start
    .fa-stack
        color #c42a86
        height 100%
        line-height 1.4em
        width 1.7em
    .p3 // TODO: give this a more meaningful name
        text-align: left
    .p1 // TODO: give this a more meaningful name
        &:after
            position absolute
            right 15%
            top 5%
            content  attr(data-count)
            font-size 43%
            font-family inherit
            padding .35em
            border-radius 50%
            line-height 0.5
            color  white
            background rgba(255,0,0,.85)
            text-align center
            min-width 1em
            font-weight 500