/**
 * @license
 * MyFonts Webfont Build ID 3923667, 2020-07-08T18:11:16-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: GalanoGrotesque-BlackItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/black-italic/
 * 
 * Webfont: GalanoGrotesque-Black by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/black/
 * 
 * Webfont: GalanoGrotesque-BoldItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/bold-italic/
 * 
 * Webfont: GalanoGrotesque-ExtraBoldItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/extra-bold-italic/
 * 
 * Webfont: GalanoGrotesque-Bold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/bold/
 * 
 * Webfont: GalanoGrotesque-ExtraBold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/extra-bold/
 * 
 * Webfont: GalanoGrotesque-ExtraLightItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/extra-light-italic/
 * 
 * Webfont: GalanoGrotesque-ExtraLight by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/extra-light/
 * 
 * Webfont: GalanoGrotesque-HeavyItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/heavy-italic/
 * 
 * Webfont: GalanoGrotesque-Heavy by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/heavy/
 * 
 * Webfont: GalanoGrotesque-Italic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/italic/
 * 
 * Webfont: GalanoGrotesque-Light by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/light/
 * 
 * Webfont: GalanoGrotesque-LightItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/light-italic/
 * 
 * Webfont: GalanoGrotesque-MediumItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/medium-italic/
 * 
 * Webfont: GalanoGrotesque-SemiBoldItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/semi-bold-italic/
 * 
 * Webfont: GalanoGrotesque-Medium by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/medium/
 * 
 * Webfont: GalanoGrotesque-ThinItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/thin-italic/
 * 
 * Webfont: GalanoGrotesque-Regular by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/regular/
 * 
 * Webfont: GalanoGrotesque-SemiBold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/semi-bold/
 * 
 * Webfont: GalanoGrotesque-Thin by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/thin/
 * 
 * Webfont: GalanoGrotesqueAlt-BlackItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-black-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-ExtraBoldItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-extra-bold-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-ExtraBold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-extra-bold/
 * 
 * Webfont: GalanoGrotesqueAlt-ExtraLight by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-extra-light/
 * 
 * Webfont: GalanoGrotesqueAlt-ExtraLightItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-extra-light-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-HeavyItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-heavy-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-Black by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-black/
 * 
 * Webfont: GalanoGrotesqueAlt-Heavy by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-heavy/
 * 
 * Webfont: GalanoGrotesqueAlt-Bold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-bold/
 * 
 * Webfont: GalanoGrotesqueAlt-Italic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-BoldItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-bold-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-MediumItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-medium-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-LightItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-light-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-SemiBoldItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-semi-bold-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-Light by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-light/
 * 
 * Webfont: GalanoGrotesqueAlt-ThinItalic by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-thin-italic/
 * 
 * Webfont: GalanoGrotesqueAlt-Medium by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-medium/
 * 
 * Webfont: GalanoGrotesqueAlt-Regular by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-regular/
 * 
 * Webfont: GalanoGrotesqueAlt-SemiBold by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-semi-bold/
 * 
 * Webfont: GalanoGrotesqueAlt-Thin by Rene Bieder
 * URL: https://www.myfonts.com/fonts/rene-bieder/galano-grotesque/alt-thin/
 * 
 * 
 * Webfonts copyright: Copyright &amp;#x00A9; 2014 by Ren&amp;#x00E9; Bieder. All rights reserved.
 * 
 * © 2020 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url('https://hello.myfonts.net/count/3bded3');

@font-face
    font-family 'GalanoGrotesque'
    src url('../fonts/GalanoGrotesque-Regular/font.woff2') format('woff2'), url('../fonts/GalanoGrotesque-Regular/font.woff') format('woff')
    font-weight normal
    font-style normal

@font-face
    font-family 'GalanoGrotesque'
    src url('../fonts/GalanoGrotesque-Regular/font.woff2') format('woff2'), url('../fonts/GalanoGrotesque-Regular/font.woff') format('woff')
    font-weight 400
    font-style normal

@font-face
    font-family 'GalanoGrotesque'
    src url('../fonts/GalanoGrotesque-Light/font.woff2') format('woff2'), url('../fonts/GalanoGrotesque-Light/font.woff') format('woff')
    font-weight 300
    font-style normal

@font-face
    font-family 'GalanoGrotesque'
    src url('../fonts/GalanoGrotesque-Medium/font.woff2') format('woff2'), url('../fonts/GalanoGrotesque-Medium/font.woff') format('woff')
    font-weight 500
    font-style normal

@font-face
    font-family 'GalanoGrotesque'
    src url('../fonts/GalanoGrotesque-SemiBold/font.woff2') format('woff2'), url('../fonts/GalanoGrotesque-SemiBold/font.woff') format('woff')
    font-weight 600
    font-style normal

@font-face
    font-family 'GalanoGrotesque'
    src url('../fonts/GalanoGrotesque-Bold/font.woff2') format('woff2'), url('../fonts/GalanoGrotesque-Bold/font.woff') format('woff')
    font-weight bold
    font-style normal

@font-face
    font-family 'GalanoGrotesque'
    src url('../fonts/GalanoGrotesque-Bold/font.woff2') format('woff2'), url('../fonts/GalanoGrotesque-Bold/font.woff') format('woff')
    font-weight 700
    font-style normal
