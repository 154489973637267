form[name="locationGoogleAdWordsCampaign"]
    margin-top 20px

.service-account-name
    margin-bottom: 5px;

.google-config, .facebook-config
    padding-top: 10px;

.app-panels
    column-count 1
    column-gap 30px

    // Medium up
    @media screen and (min-width: 992px)
        column-count 2

    .app-panel
        -webkit-column-break-inside avoid
        column-break-inside avoid
        break-inside avoid

.new-print-provider-form
    padding-top: 10px

.current-provider
    padding-bottom: 12px
    .current-provider-name
        font-weight: 500

.provider-with-sku-alert
    margin-top: 12px;