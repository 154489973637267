primaryColor = #6b30fa

#automatedEmailBuilder 
    .container-fluid 
        max-width: 1400px;

#memberEngagerNav
  background: #f9f9f9;
  min-height: 46px;
  border: 0; // Get rid of the annoying 1 pixel gap at the top & bottom
  margin-bottom: 0; // no gap underneath
  border-radius: 0; // no rounded corners because it's inline with other rectangles.

  a
    color: #111;
    background: transparent;
    border-bottom: 2px transparent solid;
    line-height: normal;

    &.active
      color: #000;
      border-bottom: 2px primaryColor solid;
      background: transparent;
      font-weight: 500;

    &:hover
      color: #000;
      border-bottom: 2px primaryColor solid;
      background: transparent;

  .navbar-nav
    margin: initial;

  .container>.navbar-header
    margin-left: 0;
    margin-right: 0;

  @media (min-width: 768px)
    .navbar-nav > li > a
        padding: 14px 0 0px 0;
        margin-right: 30px;
  
    