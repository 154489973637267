$calendar-general-color-bg = #f2f2f2
$calendar-general-color = #000
$calendar-required-color-bg = #ffeded
$calendar-required-color = #ff4f4c
$calendar-ready-color-bg = #fdf5da
$calendar-ready-ready = #f5cf47
$calendar-scheduled-color-bg = #e6f0f0
$calendar-scheduled-color = #08696b
$calendar-supported-color-bg = #eaf0fd
$calendar-supported-color = #2e69f0

.planner-month-picker
    width 317px

.calendar
    display flex
    flex-direction row
    flex-wrap wrap
    width 100%
    color black
    overflow: hidden
    .cal-header
        width 100%
        display flex
        flex-direction row
        .day-of-the-week
            padding 28px 0px 8px 17px
            flex 1
            .label
                padding 0px
                font-size 100%
                color black
                font-weight 500
            &.inactive
                opacity .4
    .cal-days
        width 14.28%
        padding 12px 0px 12px 17px
        border-top 1px solid #ccc
        .cal-label
            font-size 20px
            font-weight 300
            margin-bottom 5px
            .day
                display none
    .cell
        height 100%
    .today
        .date
            height 34px
            width 34px
            background-color #FF78D6
            border-radius 17px
            padding 4px 7px
            margin-top -4px
            margin-left -7px
            color white
    .floating
        .cal-item
            z-index 100
        .cal-item
            white-space inherit
    .cal-days:hover
        background-color #fafafa
    .before-today, .inactive
        .cal-items
            opacity .4
    
    &.well
        padding 0px

.cal-item
    display flex
    align-items flex-start
    border-left 3px solid
    font-weight 300
    margin 2px 0px
    padding 4px
    padding-left 3px
    user-select none
    white-space nowrap
    width 100%
    background-color white
    cursor pointer
    &:hover
        white-space unset
        box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    .icon
        top 1px
        margin-left 2px
        position relative
    .title
        width 89%
        line-height 14px
        padding-left 6px
        padding-top 2px
        text-overflow ellipsis
        overflow hidden
        overflow-wrap anywhere

#calendarLegend
    margin-top 1em
    display flex
    justify-content space-evenly
    .legend-entry
        padding-left 6px
        background-color transparent
        margin-left 32px
        font-weight 300
        border-left-width 4px
        border-left-style solid

.cal-legend-row
    margin-bottom 24px

@media (min-width: 769px)
    .calendar
        min-height 170px
        &.cal-week .cal-days
            min-height 350px
            .date
                display none
        &.cal-month .cal-days
            min-height 170px

@media (max-width: 768px)
    .calendar
        flex-direction column
        .inactive, .cal-header
            display none
        &.cal-week, &.cal-month
            .cal-days
                width 100%
                padding-right 17px
                padding-bottom 8px
                .cal-item
                    padding 10px
                    margin 5px 0px
                    font-size 18px
                .cal-label
                    font-size 18px
                    .day, .date
                        display inline-block
                        padding 5px 5px 0px 0px
                        font-size 100%
                        color black
                        font-weight normal
    #calendarLegend
        margin-top 2em
        flex-wrap wrap
        justify-content start
        .legend-entry
            width 220px
            margin 6px
            &.text-right
                text-align left
    .planner-period, .planner-next-prev
        text-align: center
        .btn-group
            margin-top 8px
            width 100%
            // This is not an ok thing to do
            .btn-text:not(:last-child)
                margin-right 0px
            button 
                width 50%
    .planner-month-picker
        width 100%


// TODO: Remove icons in jade when we're sure
#calendarLegend .fa
    display none
#calendarLegend .glyphicon
    display none

.cal-danger
    background-color $calendar-required-color-bg
    border-color $calendar-required-color
.cal-warning
    background-color $calendar-ready-color-bg
    border-color $calendar-ready-color
.cal-success
    background-color $calendar-scheduled-color-bg
    border-color $calendar-scheduled-color
.cal-default
    background-color $calendar-general-color-bg
    border-color $calendar-general-color
.cal-supported
    background-color $calendar-supported-color-bg
    border-color $calendar-supported-color
