color1 = #6b30fa
color2 = #ff4f4c

.tooltip-word-break
    .tooltip-inner
        word-wrap: break-word;

.bar
    text-align center

// .container
    // width: 100%
    // max-width: auto
    // padding-left 40px
    // padding-right 40px
    // -webkit-box-sizing border-box
    // box-sizing border-box
    // margin-right auto
    // margin-left auto

    // @media (max-width: 991px)
    //     .navbar-collapse
    //         margin-left -40px
    //         margin-right -40px

.bar-notify
    background-color color1
    color #fff
    .container
        padding-top 10px
        padding-bottom 10px
    .btn
        margin-left 10px
        color color1
        font-weight 500
        background-color #fff
        min-width 9em
        i.fa
            margin-right 6px
    .bar-container
        padding-top 10px
        padding-bottom 10px

.bar-expanded
    padding 20px 0
    text-align left
    background-color #fff
    color #666

.sort-icon
    margin-left 5px

.heading-with-icon
    font-size 14px
    display table
    width 100%
    > *
        display table-cell
    &:last-child
        text-align right

.locale-menu-sm
    .locale-title
        display none

.banner-notification
    margin-bottom: 0;

.checkbox
    input[type=checkbox]
        &.checkbox-no-text
            margin-left: 0;

.vertical-resize
    resize: vertical;

.input-group.phone-number-group
    select
        width: 30%;
    input
        width: 70%

.light-shadow
    -webkit-box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 10px -2px rgba(0,0,0,0.75);

.col-centered
    float: none;
    margin: 0 auto;

.card.raise-on-focus
    transition-property transform, box-shadow, opacity
    transition-duration 0.25s
    padding 15px

    &:hover, &:focus-within
        transform translate(0px, -4px)
        box-shadow 0px 3px 0px rgba(0, 0, 0, 0.1)

#breakpointMonitor
    margin 3px
    padding 0
    position fixed
    top 0
    left 0
    opacity .925
    z-index 10000
    text-transform uppercase
    font-size 10px
    font-weight 500
    color #111

    li
        padding 5px
        margin 0

    .label-xs
        background-color #b54235

    .label-sm
        background-color #b58335

    .label-md
        background-color #b5b235

    .label-lg
        background-color #649c32

user-select(value)
    -webkit-touch-callout value
    -webkit-user-select value
    -khtml-user-select value
    -moz-user-select value
    -ms-user-select value
    user-select value

emoji-picker
    .emoji-button
        background none
        border none
        cursor pointer
        opacity 0.5
        user-select none
        font-size 12pt

        &:hover
            opacity 1

    .popover-content
        padding 0

    .emoji-list
        align-content flex-start
        align-items flex-start
        display flex
        flex-wrap wrap
        height 200px
        justify-content left
        list-style none
        margin 0
        overflow-y scroll
        overflow-x hidden
        padding 0
        width 270px
        user-select(none)

        .emoji-list-item
            box-sizing border-box
            font-size 16pt
            padding 2px
            width 20%
            text-align center
            position relative

        .emoji
            cursor pointer
            padding 2px
            border none
            background none
            margin 0

            // We use an opaque square over each emoji rather than the opacity property because some emojis
            // look strange when opaque
            &:after
                background white
                position absolute
                top 0
                left 0
                opacity 0.4
                content ' '
                width 100%
                height 100%
                pointer-events none

            &:hover
                &:after
                    opacity 0

ds-textarea
    .textarea-wrapper
        position relative

        emoji-picker
            position absolute
            right 10px
            bottom 10px

.ds-dropdown
  .dropdown-menu > li > a
    padding-top 5px
    padding-bottom 5px

  &.divider
    .dropdown-menu
      & > li
        border-top 1px solid #e6e6e6

        &:first-child
          border-top none

.text-brand-pink
    color color2

.text-brand-purple
    color color1

.bg-brand-purple
    background color1

.bg-brand-black
    background black

.text-inherit-color
    color inherit
    &:hover
        color inherit
