.external-auth-apps
    .connections-table
        max-height 327px
        overflow-y scroll

    .bg-warning
        margin-top 5px
        padding 6px
        border-radius 3px

    .vertical-resize-only
        resize vertical