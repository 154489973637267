// Main dashboard. (Reports are in reports.styl)

normal-text-color = #666;
metric-difference-increase-color = #008c00;
metric-difference-decrease-color = #d80000;
metric-icon-size = 36px;

.header.dashboard-header
    background none
    background-color none

.dashboard-body
    h3
        margin-bottom 15px

    .container.main
        >.row
            // When in MEDIUM or larger make the horizontal spacing between content
            // sections the same as the vertical spacing between content sections (20px)
            @media (min-width: 992px)
                >.side-content
                    padding-right 10px

                >.main-content
                    padding-left 10px

    .well.welcome-message
        h3
            text-align center
            color normal-text-color
            margin-bottom 0

.webinar-title
    display inline-flex
    margin-top 10px
    margin-bottom 0
    margin-left 5px
    line-height 16px
    vertical-align middle
    span, a
        color black
    .fa 
        color #6b30fa

.upcoming-events-days, .upcoming-events-days ul
    margin 0
    padding 0
    list-style none

.upcoming-events-days
    .upcoming-events-day
        padding-top 5px
        padding-bottom 20px

        &:last-of-type
            padding-bottom 0

        .upcoming-events-day-label
            font-weight bold

        .upcoming-events-planners
            margin 0
            padding 0

            .upcoming-events-planner
                margin-top 7px
                margin-left 5px

                .upcoming-events-planner-icon-and-label
                    display inline-flex
                    padding 10px 10px
                    padding-bottom 8px
                    font-size 14px
                    font-weight 300
                    border-left 2px solid

                    // Should be able to use 'align-items baseline' but when I do it feels like its off by a pixel
                    // so using negative margin to correctly position label instead
                    > a
                        margin-left 10px
                        color black
                        margin-top -2px
                        overflow-wrap anywhere
                    > .fa
                        font-size 16px

                &.artifact-required
                    background-color #fdf5da
                    .upcoming-events-planner-icon-and-label
                        border-color #f5cf47

                &.built
                    background-color #e6f0f0
                    .upcoming-events-planner-icon-and-label
                        border-color #08696b

                &.no-artifact-required
                    background-color #f2f2f2
                    .upcoming-events-planner-icon-and-label
                        border-color #000

                &.no-content
                    background-color #ffeded
                    .upcoming-events-planner-icon-and-label
                        border-color #ff4f4c

                &.supported
                    background-color #eaf0fd
                    .upcoming-events-planner-icon-and-label
                        border-color #2e69f0

// Old Upcoming Planners (still used in Gallery)
.upcoming-planners-container
    .planner-entries
        display table
        border-collapse collapse
        width 100%
        a.planner-entry
            color #333
            &:hover
                text-decoration none
            &:active
                text-decoration none
            &:focus
                text-decoration none

        .planner-entry
            display table-row
            border 1px solid #ccc
            &:hover
                background-color #DDD

            .planner-entry-icon
                display table-cell
                text-align center
                vertical-align middle
                width 2em
                font-size 2em

            .planner-entry-middle
                display table-cell
                .due-text
                    &.text-warning
                        color #da6831
                    &.text-danger
                        color #e12b30
                    &.text-success
                        color #419e49

            .planner-entry-caret
                display table-cell
                text-align right
                vertical-align middle
                font-size 2em

        .planner-entry > div
            padding 0.5em

.dashboard-panel-footer
    margin-top 20px
    a
        font-family "Space Mono"

.performance-summary
    .performancy-summary-connect-apps
        text-align center
        padding-top 20px
        padding-bottom 20px

        i:first-of-type
            font-size 100px
            color #ccc

        p
            margin-top 15px
            margin-bottom 0

    table
        margin-bottom 0

    tr:last-of-type
        td
            border-bottom 1px solid #b597fc

    .current-month
        background-color #f8f8f8
        font-weight 600

    tr > th, tr > td
        padding 8px 15px

    td.metric-label
        div
            display inline-flex
            align-items center
            vertical-align middle

    .metric-icon
        // Setting the icon size using 'width' and 'height' properties is not enough to
        // force the correct size on iPhone. Need to use 'min-*' and 'max-*' (DS-993)
        min-width metric-icon-size
        min-height metric-icon-size
        max-width metric-icon-size
        max-height metric-icon-size
        margin-right 12px

    tr.metric-difference-increase
        .metric-difference
            color metric-difference-increase-color

    tr.metric-difference-decrease
        .metric-difference
            color metric-difference-decrease-color

    tr.metric
        visibility hidden

    tr.metric.metric-visible
        visibility inherit

        td
            animation fade-in 1s ease

    .metric-unavailable-message
        color darkgray

    small
        color lightgray

@keyframes fade-in
    from
        opacity 0
    to
        opacity 1
