purple = #c42a86

.agency-notification-heading
    margin-bottom: 20px

.agency-notification-refresh
    color: #428bca
    font-size: 28px
    margin-right: 5px;
    cursor pointer

.agency-no-results
    padding: 25px 0

.no-result-icon
    font-size: 40px

    &.failure
        color: red

.agency-notification-item
    padding: 15px 10px
    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2)
    border-radius: 3px
    margin: 10px 0
    cursor: default
    transition-property: transform, opacity
    transition-duration: 0.25s
    &.ng-animate
        transition-duration: 1.5s

    &.ng-enter
        transform: translate(0px, -10px);
        opacity: 0

    &.ng-enter.ng-enter-active
        transform: translate(0px, 0px);
        opacity: 1

    &.ng-leave
        transition-duration: 0s

    .agency-notification-item-content
        margin: 0
        @media (min-width: 768px) {
            display: flex
        }

    .agency-notification-item-message-container
        padding: 0 15px 0 0
        display: flex

        .agency-notification-item-message-content
            word-break: break-word;
            white-space: pre-line;
            display: grid;

    .agency-notification-stats-container
        display: flex
        padding: 0;
        @media (max-width: 767px) {
            margin-top: 15px
            padding-top: 3px
            border-top: 1px rgba(128, 128, 128, 0.1) solid
        }

        .stat-container
            width: calc(100%/3);
            text-align: center
            display: flex;
            flex-direction: column;
            justify-content: center;

            .stat-amount
                font-size: 2em
                color: purple

    .message-subject
        font-size: 16px
        font-weight: 500;
        margin-bottom: 1em;

    .message-text
        font-size: 16px

    .item-icon
        margin-right: 20px
        margin-left: 10px
        text-align: left
        margin-top: 0.25em
        font-size: 24px
        color: #3394dc

    .item-timestamp
        font-size: 0.9em
        font-style: italic

    .link-container
        overflow: hidden
        text-overflow: ellipsis
        margin: 5px 0
        .link
            white-space nowrap
        .no-link-text
            font-style: italic
            color: rgba(66, 139, 202, 0.7)

    .sent-by
        overflow: hidden
        text-overflow: ellipsis
        margin: 5px 0
        color: hsl(0, 0%, 40%)

        .sent-by-label
            font-weight: 500
            margin-right: 2px

.phone-preview-frame
    background: transparent url('../img/phoneframe.svg') top center no-repeat;
    background-size: contain;
    display: block;
    width: 200px;
    height: 900px;
    -webkit-filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, .2));
    filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, .2));
    margin-left: auto;
    margin-right: auto;

    &.phone-preview-frame-white-background
        background: transparent url('../img/phoneframe-white-background.svg') top center no-repeat;

    .phone-preview-heading
        left: 28px;
        top: 14px;
        position: relative;
        width: 150px;
        height: 24px;
        color: #FFF;

    .phone-preview-main-contents
        display: flex;
        flex-direction column

        // This sets the rectangle to exactly fit our display in the phone frame.
        left: 14px;
        top: 14px;
        position: relative;
        width: 176px;
        height: 340px;

        padding: 0 0.5em 0.5em 0.5em;

        .phone-preview-subheading
            display: flex;
            padding: 1em 0 0.75em 0;

            .phone-preview-icon
                color #2196F3;
                flex: 1;

            .phone-preview-date
                color: #727272;
                flex: 3;
                font-size: 0.75em;

        .phone-preview-scroll
            flex: 1;
            overflow-y: auto;
            margin-bottom: 0.25em;

            .phone-preview-subject
                font-size: 0.75em;
                color: #111;
                font-weight: 500;
                margin-bottom: 1em;

            .phone-preview-message
                font-size: 0.75em;
                color: #111;

            // used by the SMS builder. TODO: move the SMS styling out of agencyNotifications.styl
            .phone-preview-sms-message
                font-size: 0.85em;
                color: #000;
                margin-top: 1em;
                background: #e5e5ea;
                border-radius: 6px;
                padding: 0.5em;

        .phone-preview-call-to-action-button
            background: #c23287;
            border-radius: 4px;
            filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, .25));
            font-size: 0.75em;
            color: #FFF;
            border: none;
            padding: 0.5em;
