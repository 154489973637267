$notification-danger-color = #d80000
$notification-warning-color = #f28918
$notification-success-color = #3ea840
$notification-info-color = #4275a8

.notifications-badger-menu-item-open
    color: #3e3e3e;

.notifications-badger-menu-dropdown
    width: 30em;

    // This chunk is mostly copied from the Bootstrap dropdown stylings.
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 160px;
    padding: 0;
    margin: 0;
    font-size: 14px;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 0 0 4px 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);

.notifications-badger-menu-list
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    padding: 0;
    margin: 0;

    &.notifications-list-standalone
        margin-top: 1em;
        overflow hidden

    a:hover, a:active, a:focus
        text-decoration: none;

    &>li>div, &>li>a, &>div>li>a
        // This chunk also copied from the Bootstrap dropdown stylings.
        display: block;
        padding: 5px 4px;
        //padding: 3px 20px;
        clear: both;
        font-weight: 400;
        line-height: 1.42857143;
        white-space: normal;

    .notifications-badger-menu-no-entries
        padding: 10px 5px;

    .notifications-badger-menu-entries
        max-height: 260px;
        overflow-y: auto;

    .notifications-badger-menu-item
        &:hover
            background-color: #eeeeee;

        .text-info
            color: $notification-info-color;
            &:hover
                color: darken($notification-info-color, 20%);
        .text-success
            color: $notification-success-color;
            &:hover
                color: darken($notification-success-color, 20%);
        .text-warning
            color: $notification-warning-color;
            &:hover
                color: darken($notification-warning-color, 20%);
        .text-danger
            color: $notification-danger-color;
            &:hover
                color: darken($notification-danger-color, 20%);

    .notifications-badger-menu-item-read
        background: #F8F8F8;

    .notifications-badger-menu-item + .notifications-badger-menu-item
        border-top: 1px solid #CCC;

    .notifications-badger-menu-item-loading
        padding: 6px 5px;
        text-align: center;

    .notifications-badger-menu-item-link
        display: flex;
        padding: 10px 5px;

    .notifications-badger-menu-view-all
        background-color: #dddddd;
        padding: 2px 0 2px 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        // &:hover
        //     background-color: #eeeeee;
        a:hover
            background-color: initial;

    .notifications-badger-menu-mark-all-as-read
        background-color: #f5f5f5;
        padding: 2px 0 2px 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;

        // &:hover
        //     background-color: #eeeeee;
        a:hover
            background-color: initial;       

.notifications-badger-menu-item-link
    display: flex;

    &:hover, &:active, &:focus
        text-decoration: none;

.notifications-badger-menu-item-icon
    width: 10%;
    text-align: center;
    margin-top: 0.25em;

.notifications-badger-menu-item-message
    width: 80%;
    word-break: break-word;
    white-space: pre-line

.notification-details
    .notifications-badger-menu-item
        &:hover
            background-color: initial;

        .notifications-badger-menu-item-subject
            font-weight: 500;
            margin-bottom: 1em;

    .notifications-badger-menu-item-read
        background: initial;

    .notifications-badger-menu-item-call-to-action-button
        margin-bottom: 1em;

.notifications-badger-menu-item-timestamp
    font-size: 0.8em;

.notifications-badger-menu-item-dismiss
    width: 10%;
    text-align: center;
    margin-top: 0.25em;

    .close
        float: none;
        font-size: 16px;

.nav .open>a.notifications-badger-menu-item
    background-color: #080808;

.notifications-list-standalone
    border: 1px solid #CCC;
    border-radius: 4px;

    li.notifications-badger-menu-item:nth-child(2), li.notifications-badger-menu-no-entries:first-child
        border-radius: 4px 4px 0 0;

.notifications-badger-menu-dropdown
    left: auto;
    right: 0;

.notifications-badger-alert-container
    position: fixed;
    z-index: 500;
    margin-top: 20px;

    .notifications-badger-alert
        display: block;
        text-decoration: none;
        -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
        box-shadow: 0 6px 12px rgba(0,0,0,0.175);
        max-height: 240px;
        overflow-y: auto;
        word-break: break-word;

        &.alert-plain {
            //color: #757575;
            background-color: #ffffff;
            border-color: #e8e8e8;
        }
        &.alert-plain hr {
            border-top-color: #e0e0e0;
        }

        &:hover
            text-decoration: none;

        &.ng-enter
            transition: all 0.25s linear;
            opacity: 0;

        &.ng-enter.ng-enter-active
            opacity: 1;

        &.ng-leave
            transition: all 0.5s linear;
            opacity: 1;

        &.ng-leave.ng-leave-active
            opacity: 0;
            transform: translate(100%, 0);

@media (min-width: 768px)
    .notifications-badger-alert-container
        float: right;
        right: 0;
        margin-right: 20px;

    .notifications-badger-alert
        max-width: 30em;

@media (max-width: 767px)
    .notifications-badger-alert-container
        width: 100%;

        .notifications-badger-alert
            margin-left: 20px;
            margin-right: 20px;