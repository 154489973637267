.thumbnail-selector
    .selection-options
        .option
            width: 50%
            display: inline-block
            height: 200px
            i
                font-size: 30px
            button
                width: 100%
                height: 100%
                white-space: normal;
            &:first-child
                button
                    border-top-right-radius: 0
                    border-bottom-right-radius: 0
            &:last-child
                button
                    border-top-left-radius: 0
                    border-bottom-left-radius: 0
        &.single-option
            .option
                &:first-child
                    width: 100%
                    button
                        border-top-right-radius: initial
                        border-bottom-right-radius: initial
                &:last-child
                    display: none

    .bg-info
        margin-top: 5px
        padding: 6px
        border-radius: 3px