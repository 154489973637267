
.overview
    button
        text-transform capitalize

    .bulk-actions-button
        display inline
        margin-right 0.25em

#plannerBulkActionConfirmationTable
    max-height 300px
    overflow-y auto

// This is also used on the SpecialRequests page
#assignToAgencyManagerPicker
    .alert-warning
        margin-bottom 0
        padding 5px
        font-size 0.75em

        button
            padding 0

    select.is-unknown-user
        background-color #fcf8e3
        border-color #faebcc
        color #8a6d3b