.profile-page
    .profile-picture
        width 256px
        height 256px

        @media (min-width 992px)
            width 268px
            height 268px

        @media (min-width 1200px)
            width 335px
            height 335px

    .profile-picture-picker
        MAX_PROFILE_PICTURE_SIZE_SM = 256px
        OUTER_BORDER_RADIUS = 5px
        INNER_BORDER_RADIUS = OUTER_BORDER_RADIUS - 1

        position relative

        width MAX_PROFILE_PICTURE_SIZE_SM
        height MAX_PROFILE_PICTURE_SIZE_SM

        @media (min-width 992px)
            width 100%
            height auto

        border #ccc 1px solid
        border-radius OUTER_BORDER_RADIUS

        .overlay
            position absolute
            left 0
            right 0
            top 0
            bottom 0
            border-radius INNER_BORDER_RADIUS

        .image-controls
            transition opacity .25s
            vertical-align middle
            cursor pointer

            button
                position absolute
                cursor pointer
                color #ccc
                border none
                outline none
                color rgba(255, 255, 255, .7)

                &:hover, &:focus, &:active
                    color #fff

            .remove-image
                top 0
                right 0
                border-bottom-left-radius INNER_BORDER_RADIUS
                border-top-right-radius INNER_BORDER_RADIUS
                padding 5px 10px
                background-color rgba(217, 83, 79, .7)

                &:hover, &:focus, &:active
                    background-color rgb(201, 48, 44)

            .change-image
                bottom 0
                width 100%
                border-bottom-left-radius INNER_BORDER_RADIUS
                border-bottom-right-radius INNER_BORDER_RADIUS
                padding 10px 10px
                text-align center
                background-color rgba(0, 0, 0, .6)

                &:hover, &:focus, &:active
                    background-color #000

            &:hover
                button
                    color #fff

                .remove-image
                    background-color rgb(217, 83, 79)

                    &:hover
                        background-color rgb(201, 48, 44)

                .change-image
                    background-color rgba(0, 0, 0, .75)

                    &:hover, &:focus, &:active
                        background-color #000

        .progress-overlay
            display inline-flex
            flex-direction column
            align-items center
            justify-content center
            background-color rgba(0, 0, 0, .5)

            .progress
                margin-bottom 0
                width 90%

