color1 = #3394dc
color2 = #c42a86

primaryColor = #6b30fa
primaryColorActive = #602BE0
secondaryColor = #FF4F4C

.breadcrumb.breadcrumb-flex
    display flex
    flex-direction row
    justify-content start
    align-content start

    & > li
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
        flex-shrink 0

        &:last-of-type
            flex-shrink 1

.ta-invert .ta-editor
    background-color #000

.ta-editor.ta-html, .ta-scroll-window.form-control
    height: 300px; // DS-2418: restrict rich text editor height, so formatting toolbar is always visible.

.form-control.max-file-size
    width: 150px;

.email-builder-sections
    .btn.document-button
        outline none

        &:first-of-type
            border-top-left-radius 4px !important
            border-top-right-radius 4px !important

.layer-section-scrollable-content
    max-height: 520px;
    overflow-y: scroll;

.layer-section-scrollable-email
    max-height: 640px;
    overflow-y: scroll;

.bootstrap-switch
    font-family "Space Mono", sans-serif
.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary, .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-primary
    background primaryColor

#contentBuilderContainer
    .container-fluid
        max-width: 1400px;

    .keyboard-shortcuts
        padding-left: 10px;
        color: #666;

    .canvas-container
        position relative
        min-height: 100%;

    .canvas-spinner-overlay
        position absolute
        z-index 990
        background-color rgba(196, 196, 196, 0.68)
        width 100%
        height 99%
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0px;

        .loading-container
            border-radius 50%
            background-color #FFFFFF
            position absolute

            .loading
                width 140px
                height 140px
                margin auto

    #contentBuilderCanvas
        position relative
        border 1px solid #000

        &.action-pan
            cursor grab
            cursor -webkit-grab
            cursor -moz-grab

        &.action-pan.active
            cursor grabbing
            cursor -webkit-grabbing
            cursor -moz-grabbing

        &.action-move
            cursor move

        &.action-move.active
            cursor move

        &.action-rotate
            cursor grab
            cursor -webkit-grab
            cursor -moz-grab

        &.action-rotate.active
            cursor grabbing
            cursor -webkit-grabbing
            cursor -moz-grabbing

        &.action-resizeWidth
            cursor ew-resize

        &.action-resizeHeight
            cursor ns-resize

        &.action-resizeWidthAndHeight
            cursor nwse-resize

    #builderToolsRow
        margin-top 1em
        .btn
            padding-top 6px
            padding-bottom 6px
            border 0
            border-radius 4px


    .zoom-container
        position: absolute;
        bottom: 6px;
        margin-left: 1px;

    .zoom-percentage
        display inline-block
        opacity 1
        padding: 0 5px;
        background: rgba(211, 211, 211, 0.85);

        &.fade-hide
            opacity 0
            -webkit-transition all linear 1s
            transition all linear 1s

    .builder-editor-property-label
        width 100%
        -webkit-user-select none /* Chrome all / Safari all */
        -moz-user-select none /* Firefox all */
        -ms-user-select none /* IE 10+ */
        /* No support for these yet, use at own risk */
        -o-user-select none
        user-select none

    .builder-editor-group-label
        width 100%
        background-color #eee
        padding 0.4em 0.4em
        border-radius 4px
        border-color #bbb
        border-width 1px
        border-style solid
        -webkit-user-select none /* Chrome all / Safari all */
        -moz-user-select none /* Firefox all */
        -ms-user-select none /* IE 10+ */
        /* No support for these yet, use at own risk */
        -o-user-select none
        user-select none

    .builder-editor-group-label.expanded
        border-bottom-left-radius 0
        border-bottom-right-radius 0

    editor-field-group
        .editor-field-properties
            border 1px solid #BBB
            margin-bottom 5px
            margin-top -0.4em
            padding 0.5em
            padding-top 0.6em
            padding-bottom: 0
            border-bottom-left-radius 6px
            border-bottom-right-radius 6px
            background-color #FAFAFA

            editor-field-label
                label
                    padding-left 0.1em

    .text-substitution-field
        //.value
        //	display inline
        &:hover .configure
            display inline
        .configure
            display none
            margin-left 0.5em
            .glyphicon
                vertical-align top

    .properties-column
        scrollbar-gutter: stable
        &:has(.color-picker__dialog:not(.ng-hide)) {
            overflow: hidden
        }
        @media (min-width: 992px) {
            min-height: 200px;
            max-height: 400px;
            overflow-y auto
        }
        @media (min-width: 1200px) {
            max-height: 530px;
        }

    .layer-type
        min-width 20px
        text-align center
        display inline-block
        margin-right 5px

    .channel-data-layer-icons
        float right
        color #c42a86

    .channel-data-button.btn-primary
        .channel-data-layer-icons
            color #fff

    .document-button.btn
        border-radius 0
        border-bottom none
        outline none
        border-color #FFF

        &:last-of-type
            border-bottom 1px solid #fff
            border-bottom-left-radius 4px
            border-bottom-right-radius 4px

        .layer-type
            margin-left 17px

    .document-button.btn:active, .document-button.btn:focus, .document-button.btn:hover
        color primaryColor

    .no-layers
        .document-button.btn
            &:first-of-type
                border-top-left-radius 4px
                border-top-right-radius 4px

    .layer-group
        display table
        width 100%

        .layer-entry
            display table-row
            width 100%

            .btn
                border-radius 0
                border-color #fff
                border-bottom none
                outline none

            &:first-of-type
                .layer-dropdown
                    .btn
                        border-top-right-radius 4px

            .btn {
                color: #333;
                background-color: #fff;
                border-color: #ccc
            }

            .btn:hover, .btn:focus
                background-color #f2f2f2

            .btn:active, .btn.active, .open > .dropdown-toggle.btn {
                color: #333;
                background-color: primaryColor;
                border-color: primaryColor
            }

            .btn:active, .btn.active, .open > .dropdown-toggle.btn {
                background-image: none
            }

            .btn.disabled, .btn[disabled], fieldset[disabled] .btn, .btn.disabled:hover, .btn[disabled]:hover, fieldset[disabled] .btn:hover, .btn.disabled:focus, .btn[disabled]:focus, fieldset[disabled] .btn:focus, .btn.disabled:active, .btn[disabled]:active, fieldset[disabled] .btn:active, .btn.disabled.active, .btn[disabled].active, fieldset[disabled] .btn.active {
                background-color: #fff;
            }

            .btn .badge {
                color: #fff;
                background-color: #333
            }

            &:first-of-type
                .layer-handle
                    border-top-left-radius 4px

            .layer-handle
                display table-cell

                background-color #fff
                background-image url('../img/move-handle.png')
                background-repeat no-repeat
                background-position center

                height 12px
                width 17px

                cursor -webkit-grab
                margin-left -5px
                margin-right: 5px

                border 1px #fff solid
                border-right none
                border-bottom none

            &.ui-sortable-helper
                &:not(.layer-selected)>*
                    border-bottom 1px #ccc solid

                &.layer-selected>*
                    border-bottom 1px primaryColor solid

                &>*
                    cursor -webkit-grabbing !important

                .layer-handle
                    border-top-left-radius 4px
                    border-bottom-left-radius 4px

                .layer-dropdown, .layer-dropdown > .dropdown-toggle
                    border-top-right-radius 4px
                    border-bottom-right-radius 4px

            &.layer-selected
                &:not(.layer-invisible)
                    .layer-handle
                        background-color primaryColor
                        background-image url('../img/move-handle-selected.png')
                        border-color primaryColor

                &.layer-invisible .layer-handle
                    background-color #e3e3e3
                    border-color #fff

            .layer-label
                display table-cell
                max-width 0

                button
                    width 100%
                    border-top-right-radius 0
                    border-bottom-right-radius 0
                    border-right 0
                    text-align left
                    overflow hidden
                    text-overflow ellipsis

            .layer-visibility-toggle
                display table-cell
                width 1em

                button
                    width 100%
                    border-radius 0
                    border-right 0
                    text-align left

            .layer-dropdown
                display table-cell
                width 1em

                button
                    width 100%
                    border-top-left-radius 0
                    border-bottom-left-radius 0

        .layer-entry.layer-selected
            .btn {
                color: #fff;
                background-color: primaryColor;
                border-color: primaryColor
            }

            .btn:hover, .btn:focus, .btn:active, .btn.active, .open > .dropdown-toggle.btn {
                color: #fff;
                background-color: primaryColorActive;
                border-color: primaryColorActive
            }

            .btn:active, .btn.active, .open > .dropdown-toggle.btn {
                background-image: none
            }

            .btn.disabled, .btn[disabled], fieldset[disabled] .btn, .btn.disabled:hover, .btn[disabled]:hover, fieldset[disabled] .btn:hover, .btn.disabled:focus, .btn[disabled]:focus, fieldset[disabled] .btn:focus, .btn.disabled:active, .btn[disabled]:active, fieldset[disabled] .btn:active, .btn.disabled.active, .btn[disabled].active, fieldset[disabled] .btn.active {
                background-color: #428bca;
                border-color: #fff
            }

            .btn .badge {
                color: #428bca;
                background-color: #fff
            }

            &.layer-invisible
                .btn
                    border-color #ccc

        .layer-entry.layer-invisible
            .btn {
                color: #666;
                background-color: #E3E3E3;
            }

            .btn:hover {
                color: #555;
                background-color: #E8E8E8;
            }

            .btn:focus {
                color: #555;
                background-color: #E0E0E0;
            }

            .btn:active {
                color: #555;
                background-color: #E0E0E0;
            }

    //#zoomControls
    //float left

    #transformControls
        float right

    .tool-panel
        border 0
        border-radius 4px
        background-color #FAFAFA
        margin-bottom 0.5em
        width: 100%
        box-shadow 0 2px 4px 0 rgba(0,0,0,0.2)

        .btn
            border-top none
            border-bottom none
            &.btn-default
                padding: 6px 11px;
            &.left-edge
                border-left none

            &.right-edge
                border-right none

    #animationControls, #videoControls
        min-height 2.43em

        .right-buttons
            float right

        .anim-frame-counter
            display inline-block
            min-width 8em
            text-align center
            position relative
            float left
            font inherit
            padding 6px
            color #333
            background-color #fff
            border-color #ccc

        .duration-input-container
            width 90%
            text-align center
            margin-left auto
            margin-right auto

    #videoControls
        .mute-button
            width 3em

    .simple-properties-container
        @media (min-width: 990px) {
            max-height 530px
        }
        overflow-y auto

    .publish-row
        @media (max-height: 1200px) {
            padding-top: 10px;
        }

    .print-template-page-picker
        margin-bottom 0.5em;

.emailTemplateDisplay
    width 100%
    height 630px

#contentBuilderPublishCanvas
    border 1px solid #000

#image-chooser
    .image-entries
        max-height 300px
        overflow-y auto

        .row
            margin-left 0
            margin-right 0

        .image-entry
            padding 0.5em

            .thumbnail
                display inline-block
                text-align center
                height 100%

                a img
                    border 1px solid

                .upload-glyph
                    width 256px
                    height 256px
                    display table-cell
                    vertical-align middle

@media (min-width: 768px)
    #image-chooser
        .row-eq-height
            display -webkit-box
            display -webkit-flex
            display -ms-flexbox
            display flex
        .col
            display flex
            .well
                align-items stretch
                width 100%

.colour-scheme-choice
    display inline-block

    input[type="radio"]
        margin-right 5px

        &:last-of-type
            margin-left 5px

    span
        margin-right 5px

.color-picker-dropdown-button
    border 1px solid #91765d
    background rgb(238, 238, 238)
    width 49px
    cursor pointer

    &:hover
        border-color #F0C49B

    .color-picker-dropdown-button-preview
        padding 4px
        width 48px
        height 28px

        .color-picker-dropdown-button-preview-inner
            display inline-block
            width 25px
            height 20px
            border 1px solid #000
            background-image url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==')
            background-repeat repeat

        .color-picker-dropdown-button-arrow
            color #000
            font-size 10px
            display inline-block
            position relative
            left 5px
            top -6px

.page-number
    display inline-block
    min-width 8em
    text-align center
    position relative
    float left
    font inherit
    padding 6px 12px
    color #333
    background-color #fff
    border-color #ccc

@media (min-width: 992px)
    .mins-before
        padding: 0
        padding-top: 7px;

.save-template
    .files-uploading
        max-height: 320px;
        overflow: hidden;
        overflow-y: scroll;

.bootstrap-switch
    .bootstrap-switch-container
        display: inline-flex;

.publish-errors
    .publish-error
        p
            white-space: pre;

.send-to-print
    .print-provider-selection
        .print-provider
            padding: 8px;
            box-shadow: 0px 1px 3px grey;
            background-color: #fff;
            border-radius: 4px;
            margin-bottom: 7px;
            cursor: pointer;
            transition-property: transform;
            transition-duration: 0.3s;
            &:hover
            &:active
                transform: translate(0px, -1px);
            .print-provider_business-name
                font-weight: 500
            .print-provider_email
                color: grey
            .provider-selected
                color: green
                font-size: 18px

        .other-print-provider
            padding: 0 3px

        .print-provider-separator
            margin: 12px 0 9px
            border-top: 1px solid darkgrey

    .print-provider-actions
        margin-top: 15px

    .print-request-details
        .instructions
            resize: vertical

.advanced-editor-toggle
    min-width: 100px;

#emailSectionSimplePicker
    margin-bottom: 1.5em;

.e-mail-container-spinner-overlay
    position absolute
    z-index 990
    background-color rgba(196, 196, 196, 0.68)
    width 100%
    height 99%
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;

    .loading-container
        border-radius 50%
        background-color #FFFFFF
        position absolute

        .loading
            width 140px
            height 140px
            margin auto

.e-mail-container
    position relative
    min-height: 100%;
    user-select: none;
    &.mobile-viewport
        display: flex
        justify-content: center
        margin-bottom: 0.5em;
        #emailTemplateDisplay
            max-width: 350px


.viewport-toggle
    margin-bottom: 1em;

    .btn:first-child
        margin-right: 5px;

    @media (max-width: 575px) {
        display: none;
    }


textarea.textarea-code
    font-family: monospace;
    white-space: nowrap;


publish-email-to-fitware, publish-automated-email-to-fitware
    ul.height-constrained-list
        max-height 10em
        overflow-y auto
