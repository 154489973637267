.color-picker__button
    border 1px solid #999
    border-radius 3px
    width 30px
    height 30px
    margin-top 3px
    display inline-block
    cursor pointer
    user-select none
    -moz-user-select none
    -webkit-user-select none
    overflow hidden

    &:hover
        border-color #666
        box-shadow 0 0 1pt 1pt #ddd

.color-picker__button__color-preivew

.color-picker__dialog
    position absolute
    top 32px
    border 1px solid #888
    background #fff
    z-index 10000
    padding 10px
    height 280px
    width 390px
    user-select none
    -moz-user-select none
    -webkit-user-select none

.color-picker__dialog__tabs
    margin-bottom: 4px
    .color-picker__dialog__tabs-item
        cursor: pointer
    .color-picker__dialog__tabs__tab
        line-height: 0.2
        padding: 9px 7px;

.color-picker__dialog-swatches
    max-height: 235px
    overflow-y: scroll
    display: flex;

.color-picker__column
    width: 50%;

.color-picker__column-header
    padding:4px 5px 0;

.color-picker__hr
    margin-left:0;
    margin-right:0;

.color-picker__dialog-preview-static
    cursor: pointer
    padding: 4px 5px 0;
    display:flex;
    align-items:center;
    &:hover
        background-color: #eee
    .swatch-color-preview
        display: inline-block
        border 1px solid #ccc
        border-radius 3px
        height: 30px
        width: 30px
    .swatch-color-name
        display: inline-block;
        position: relative;
        top: -5px;
        padding-left: 5px;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;



.color-picker__dialog__spectrums
    display inline-block

.color-picker__dialog__spectrums__sat-lum
    border 1px solid #999
    display inline-block
    background #efefef
    cursor crosshair

.color-picker__dialog__spectrums__hue
    border 1px solid #999
    display inline-block
    background #efefef
    cursor crosshair
    margin-left 5px

.color-picker__dialog__form
    display inline-block
    position absolute
    margin-left 6px
    height 202px
    width 135px

.color-picker__dialog__form__scheme
    display inline-block
    margin-right 6px

    input[type="radio"]
        display none

    label
        border 1px solid #ccc
        border-radius 3px
        padding 0 4px
        cursor pointer
        font-weight normal
        font-size 12px
        text-transform uppercase
        outline 0
        &:hover
            background #efefef
    input[type="radio"]:checked
        & + label
            border 1px solid #adadad
            background #e6e6e6
            box-shadow inset 0 3px 5px rgba(0,0,0,.125)

.color-picker__dialog__form__inputs
    padding-left 3px

.color-picker__dialog__form__input
    margin-top 2px

    label
        display inline-block
        width 60px
        margin-right 5px
        font-weight normal
        font-size 12px
    input
        display inline-block
        width 60px
        text-align right
        padding 2px 5px
        font-size 12px
        border 1px solid #ccc
        border-radius 2px

.color-picker__dialog__footer
    position absolute
    bottom 10px
    width 368px

.color-picker__dialog__preview
    border 1px solid #ccc
    border-radius 3px
    margin 0
    background #efefef
    position absolute
    bottom 0
    display none

.color-picker__dialog__text-value
    width 234px
    position absolute
    bottom 0
    border 1px solid #ccc
    border-radius 3px
    font-size 12px
    outline none
    padding 3px 5px
    text-transform uppercase

.color-picker__dialog__cancel
    position absolute
    bottom 0
    right 0
    font-size 12px
    background #fff
    border 1px solid #ccc
    border-radius 3px
    padding 3px 5px
    outline 0

    &:hover
        background #efefef

    &:active
        border 1px solid #adadad
        background #e6e6e6
        box-shadow inset 0 3px 5px rgba(0,0,0,.125)

