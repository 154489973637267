#specialRequestsList
    .special-request-status
        padding 4px
        border-radius 4px
        text-align center
        margin 0
        border 1px solid #ccc

    .bg-muted
        background-color #eee

.special-request-details
    .date-required-group
        margin-bottom 0
    .deliverables-options
        padding-left 0
    .deliverables-selected
        padding-left 1em
    @media (max-width: 991px) {
        .deliverables-selected {
            padding-left 1em
        }
    }
    .audit-dates
        .audit-date-entry
            label
                float left
            p
                float right
                text-align right
                margin-bottom 0
    
    .special-request-form-date-input
        cursor: pointer

.comments-container
    .flex-row
        display flex
        align-items center
    .comment-footer
        margin 4px 0px 14px 0px
        p
            margin 0px
            &.pull-right
                margin-left auto
