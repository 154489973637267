color1 = #6b30fa
color2 = #ff4f4c

#tryThisList
    list-style-type none
    border 1px solid #AAA
    border-radius 4px
    padding 0

    li
        padding 5px
        padding-bottom 0.15em
        border-bottom 1px solid #DDD

        a:hover
            text-decoration none

        .title:hover
            text-decoration underline

        .subtitle
            font-size 0.8em
            color #AAA
            //padding-right 0.5em
            text-align right

    li.heading
        color #FFF
        background #ff4f4c
        padding-top 0.15em

    li.item:last-child
        border-bottom 0

    li.item:nth-child(odd)
        background-color #EEE

    .page-nav
        background-color #F7F7F7
        height 2.2em
        font-size 0.9em

.body
    span.trythis-tag
        color #fff
        background-color color2
    a.trythis-tag
        max-width 100%
        overflow hidden
        background-color transparent
        color color1
        margin-right 0
        margin-bottom 0
        font-weight 300
    a.trythis-tag:hover
        text-decoration underline
        color color1
        background-color transparent
.trythese
    a.trythis-tag
        padding-left 0
        padding-right 0
        margin-right 12px
    h4
        color color1

.trythis-thumb
    word-wrap break-word

    .trythis-thumbnail
        width 100%
        max-width 256px
        height 90px
        display inline-block
        text-align center
        overflow hidden
        img
            width 100%
            height auto
    h3
        margin-top 0
        font-size 1.3em
        a
            color #333
        a:hover
            text-decoration none
            color color1
    .col-md-8
        padding-left 0
@media (max-width: 1199px)
    .trythis-thumb
        .trythis-thumbnail
            height 70px
@media (max-width: 991px)
    .trythis-thumb
        .trythis-thumbnail
            height 120px
        .col-md-8
            padding-left 15px
        h3
            margin-top 15px
@media (max-width: 768px)
    .trythis-thumb
        text-align center

.trythis-feature
    .trythis-image-content
        float right
        max-width 50%
        padding 0.5em
        margin 0 0 2em 2.5em
        .video-sizing
            max-height: 600px

    .trythis-text-content
        white-space pre-wrap
        text-align left

@media (max-width: 768px)
    .trythis-feature
        .trythis-image-content
            float none
            max-width 100%
            margin 0 0 1em 0

#tryThisMenu
    margin-top 1.5em
    h3
        font-size 1.05em
        font-weight 500
        color black
        font-family "Space Mono"

    h4
        margin-bottom 0.2em
        color color1
        font-size 1 em

    ul, ul li
        list-style none
        padding-left 0

        a
            color #666

            &.active
                color color2

    .try-this-menu-sub-section
        padding-left 1px

    .try-this-menu-item
        margin-top 0.4em

    .try-this-menu-sub-section-content
        font-size 0.95em
        font-weight 300
        padding-left 5px
        margin-left 10px

    .try-this-menu-section + .try-this-menu-section
        margin-top 1.5em