color1 = #3394dc
color2 = #c42a86

primaryColor = #6b30fa
primaryColorActive = #602BE0
secondaryColor = #FF4F4C
successColor = #08696B
successColorActive = #83b4b5
whiteColor = #fff
blackColor = #000
blackColorActive = #808080

html
    font-size 14px

@media (min-width: 768px) {
  .container {
    width: auto;
    max-width: 1440px;
  }
}

@media print
   a[href]:after
      visibility: hidden

.label
    font-weight 600

.btn
    font-family "Space Mono"
    border-radius 6px
    padding 12px
    color whiteColor
    border none
a
    &.btn
        padding 7px
        &.btn-xs
            padding 4px 16px

.btn-primary
    background-color primaryColor
    border 1px solid primaryColor
    &:hover, &:active, &:focus, &[disabled]
        background-color primaryColorActive
        border 1px solid primaryColorActive
.btn-default
    color blackColor
    border solid 1px primaryColor
    background-color whiteColor
    &:hover, &:active, &:focus, &[disabled]
        background-color whiteColor
        border solid 1px primaryColorActive
    i
        color primaryColor
    .glyphicon
        color primaryColor
.btn-success
    background-color successColor
    border 1px solid successColor
    &:hover, &:active, &:focus
        background-color successColorActive
        border 1px solid successColorActive
.btn-link
    color: primaryColor
    &:hover
        color: primaryColorActive
.btn-sm
    padding 5px 8px
    font-size 13px
.btn-xs
    padding 4px 16px
    font-size 13px
    min-width 16px

.form-control
    height auto

.btn-text
    background-color transparent
    border none
    color primaryColor
    padding 0px
    font-weight 300
    display flex
    align-items center
    .fa, .fal
        margin 0px 6px
        padding-top: 3px
    &:hover, &:active, &:focus, &[disabled]
        color primaryColorActive

.btn-group
    .btn-text:not(:last-child)
        margin-right 40px

.input-group
    // TODO: this should not cound if also last
    .form-control
        height 46px
        border-radius 6px
        border solid 1px primaryColor
        background-color whiteColor
        padding 22px 12px
    .btn
        min-width auto

.input-group select.form-control
    padding 6px 12px

.well
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding 24px

.location-picker
    .btn.btn-default
        font-family GalanoGrotesque, sans-serif
        padding 9px
        border solid 1px #e6e6e6
        color blackColor
        font-weight normal
        padding 13px 20px

body
    padding-top 69px
    color blackColor
    background-color #F2F2F2
    font-size 14px
    font-family GalanoGrotesque, sans-serif
a
    color primaryColor
    &:active, &:focus, &:hover
        color primaryColorActive
        text-decoration none

/* General styles */
.header
    background-color #f2f2f2
    padding: 10px 0px

.main
    padding-top 20px

.pad-bottom
    padding-bottom 20px
.pad-top
    padding-top 38px
.pad-bottom-sm
    padding-bottom 6px

h2
  font-family GalanoGrotesque, sans-serif
  font-size 32px
  font-weight 500
  font-stretch normal
  line-height 1.38
  letter-spacing normal
  color #000000
  margin-top 0
  i
    display none !important
h3
  font-family GalanoGrotesque, sans-serif
  font-size 28px
  font-weight 500
  font-stretch normal
  font-style normal
  line-height 1.25
  letter-spacing normal
  color blackColor
  margin-top 0
  a
    color blackColor
    &:hover, &:focus, &:active
        color blackColorActive
h4
  font-family GalanoGrotesque, sans-serif
  font-size 20px
  font-weight normal
  font-stretch normal
  font-style normal
  line-height 1.3
  letter-spacing normal
  color activeColor

h6
    font-size 20px
    font-weight 500
    font-family GalanoGrotesque, sans-serif
    margin-bottom 8px

.nav-pills>li.active>a
    background-color primaryColor
    &:active, &:focus, &:hover
        background-color primaryColorActive

.nav-pills>li:not(.active)>a
    background-color transparent
    color primaryColor
    &:active, &:focus, &:hover
        background-color transparent
        color primaryColorActive


.text-striking
    color secondaryColor

.vertical-center
    display flex
    align-items center
    h2
        margin-bottom 0px

ol.breadcrumb
    background-color transparent
    border 0
    padding 0
    margin-bottom 6px

@media print {
    .hide-print {
        display: none !important;
    }
}

.clear
    clear both

.loading
    background transparent url('../img/loading.gif') center center no-repeat
    width 128px
    height 128px
    margin 0 auto

.loading-small
    display inline-block
    background transparent url('../img/loading-small.gif') center center no-repeat
    width 32px
    height 32px
    vertical-align bottom

// this might need to change

.no-results
    margin 0 auto
    text-align center
    font-size 1.5em
    color #CCC

.no-results .glyphicon
    font-size 2em

/* Panels */
.panel-primary
    border-color #fff
    .panel-heading
        background-color #fff
        border-color #fff
        font-size 2em
        font-weight 500
        color #000
        .panel-title
            font-size 1em
            font-weight 500
        button.close
            margin-top -0.125em
            margin-right -0.2em

        button.close:hover
            opacity 1


footer
    padding 20px 15px 20px 15px
    border-top 1px solid #ccc
    margin-top 60px

.button-space-top
  margin-top 10px

.space-right
    margin-right 16px

.space-left
    margin-left 16px

.inline-spacer
    margin-right 10px

.form-inline
    label
        margin-right 10px
    input
        margin-right 10px

.toast-top-right
    top 40px

#toast-container.toast-bottom-full-width
    .toast
        /*
            Limiting the height of toasts to a reasonable level to avoid page long errors showing.
            Refer to: https://digitalstack.atlassian.net/browse/DSL-1903
        */
        overflow: auto
        max-height: 200px
        margin-bottom 7px

h2
    input
        font-size 30px

.comment
    margin-bottom 0
    padding-bottom 0

.commentmod
    font-weight 500

.author
    color #ccc
    font-size 12px
    margin-top 0
    padding-top 0
    font-style italic
    a
        color #ccc
        text-decoration underline

.drop-hover
    background-color #fafafa !important

.large
    font-size 28px
    margin-right 12px
    vertical-align bottom
    font-weight 500

.well
    background-color #fff
    margin-bottom 30px
    border-width 0px

.welldanger
    color #fff
    background-color #e3504e

.wellsuccess
    color #FFF
    background-color #51b960

.welcome
    text-align center
    margin-bottom 20px
    h2
        color color1

.uploading
    background-color #fff
    padding 10px 10px 0 10px
    //overflow hidden
    white-space nowrap
    border 1px #ccc solid
    border-radius 6px

    .progress
        margin-bottom 10px

    .filename
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
        padding-bottom 10px
        text-align center

.uploading-wrapper
    padding-bottom: 10px;

    .uploading
        background-color #fff
        white-space nowrap
        border 1px #ccc solid
        border-radius 6px
        padding: 0;

        .progress
            margin: 22px 5px;
            background-color: #e8e8e8;

        .filename
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: center;
            padding: 10px 8px;
            word-break: normal;
            border-top: 1px solid rgba(204,204,204,0.5);

OUTER_BORDER_RADIUS = 5px;
INNER_BORDER_RADIUS = OUTER_BORDER_RADIUS - 1;

.upload-block
    background-color #fff
    border 1px solid #ccc
    border-radius OUTER_BORDER_RADIUS

    a:hover
        text-decoration none

        .filename
            text-decoration underline

    .upload-image
        background-position center center
        background-size 100%
        background-repeat no-repeat
        height 64px
        border-top-left-radius INNER_BORDER_RADIUS
        border-top-right-radius INNER_BORDER_RADIUS

    .filename
        border-top 0
        padding 10px 8px
        text-align center
        word-break break-all
        white-space nowrap

    &.new
        transition-property transform, box-shadow
        transition-duration 0.25s
        margin-bottom: 10px;

        &:hover
            transform translate(-1px, -1px)
            box-shadow 3px 3px 10px rgba(0, 0, 0, 0.15)

        .filename
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: normal;
            border-top: 1px solid rgba(204, 204, 204, 0.5);

        .delete-button
            background: white;
            color: darkred;
            border: none;
            float: right;
            z-index: 100;
            border-top: 1px #ccc solid;
            border-right: 1px #ccc solid;
            box-shadow: -2px 2px 4px -1px black;
            &:hover
                background: #f5f5f5;
            .glyphicon
                margin-top: 2px;

        &.image-overlay
            vertical-align middle
            position relative

            &:hover
                button.remove-image
                    background-color rgb(217, 83, 79)

                    &:hover, &:focus, &:active
                        background-color rgb(201, 48, 44)

                a.view-image
                    background-color rgba(66, 139, 202, .8)

                    &:hover
                        background-color rgb(66, 139, 202)

            button
                position absolute
                cursor pointer
                color #ccc
                background-color rgba(0, 0, 0, .3)
                border none
                outline none

                &:hover, &:focus, &:active
                    color #fff
                    background-color rgba(0, 0, 0, .6)

                &.remove-image
                    top 0
                    right 0
                    border-bottom-left-radius INNER_BORDER_RADIUS
                    border-top-right-radius INNER_BORDER_RADIUS
                    padding 5px 10px
                    background-color rgba(217, 83, 79, .7)
                    color #fff
                    transition background-color .25s

                    &:hover, &:focus, &:active
                        background-color rgb(201, 48, 44)

            a.view-image
                position absolute
                cursor pointer
                color #ccc
                background-color rgba(0, 0, 0, .3)
                border none
                outline none
                top 0
                left 0
                border-bottom-right-radius INNER_BORDER_RADIUS
                border-top-left-radius INNER_BORDER_RADIUS
                padding 5px 10px
                background-color rgba(66, 139, 202, .7)
                color #fff
                transition background-color .25s

                &:hover, &:focus, &:active
                    background-color rgb(66, 139, 202)

.upload-glyph
    text-align center
    width 100%
    height 64px
    background-color #fff
    border-top-left-radius INNER_BORDER_RADIUS
    border-top-right-radius INNER_BORDER_RADIUS
    border-bottom-left-radius 0
    border-bottom-right-radius 0
    border-bottom 1px #ccc solid
    font-size 42px
    color #c10f86
    display flex
    align-items center
    justify-content center

label
    font-weight 500

.label-spacer
    margin-right 8px
    margin-bottom 8px


.no-shadow
    box-shadow none
    -webkit-box-shadow none

.clickable
    cursor pointer

.unselectable
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

.radio
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

.overview-attachments li
    list-style-type none



.trythis-tag
    display inline-block

.branded-primary
    color #3992D1

.branded-alternate
    color black

.tag-line
    color color2
    font-size 2.5em
    margin-bottom 1.5em
.stack-client-logos
    margin-bottom 2em
.stack-client-logo
    width 241px
    margin 1em

@media (max-width: 991px)
    .tag-line
        font-size 2em
    .stack-client-logo
        width 120px



.with-headroom
    margin-top 4em

.deemphasis
    color #AAA

    a
        color #AAA
        text-decoration none

    a:hover
        color #666

.bg-tr-inactive
    background-color #EEE

.bg-padding
    padding 6px
    border-radius 3px
    box-shadow: 0px 0px 2px 0px rgb(180, 179, 179);

.faint
    color #cfcfcf

.smaller
    font-size: 0.8em

.dropdown-link
    display block
    padding 3px 20px
    clear both
    font-weight 400
    line-height 1.42857143
    color #333
    white-space nowrap
    cursor pointer

.dropdown-link:focus, .dropdown-link:hover
    color #262626
    text-decoration none
    background-color #f5f5f5
    outline-offset -2px

.dropdown-multiselect li>a[disabled]
    cursor: not-allowed
    color: #d8d8d8

.fix-glyphicon
    line-height 1.3em


.document-button
    width 100%
    text-align left

.bootstrap-switch-pull-right
    float: right !important;

.tag-pill
    //border-radius 4px
    display inline-block
    margin-right 8px
    margin-bottom 8px
    //background-color #777
    font-size 75%
    //padding .2em .6em .3em
    //line-height 1
    color #FFF
    font-weight 500
    text-align center
    white-space nowrap
    vertical-align baseline

    .tag-pill-left
        display inline
        padding 0.3em 0.5em
        background-color #777
        border-top-left-radius 4px
        border-bottom-left-radius 4px

    .tag-pill-right
        //border-left 1px solid #DDD
        border 1px solid
        border-top-right-radius 4px
        border-bottom-right-radius 4px
        display inline
        padding 2px 0.3em
        background-color #DDD
        color #888
        cursor pointer

    a.tag-pill-right:hover
        color #AAA
        text-decoration none

#chartjs-tooltip {
	opacity: 0; // hidden by default
	position: absolute;
	z-index: 1000;
	background: rgba(0, 0, 0, .7);
	color: white;
	padding: 3px;
	border-radius: 3px;
	-webkit-transition: all .1s ease;
	transition: all .1s ease;
	pointer-events: none;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}
#chartjs-tooltip.below {
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}
#chartjs-tooltip.below:before {
	border: solid;
	border-color: #111 transparent;
	border-color: rgba(0, 0, 0, .8) transparent;
	border-width: 0 8px 8px 8px;
	bottom: 1em;
	content: "";
	display: block;
	left: 50%;
	position: absolute;
	z-index: 99;
	-webkit-transform: translate(-50%, -100%);
	transform: translate(-50%, -100%);
}
#chartjs-tooltip.above {
	-webkit-transform: translate(-50%, -100%);
	transform: translate(-50%, -100%);
}
#chartjs-tooltip.above:before {
	border: solid;
	border-color: #111 transparent;
	border-color: rgba(0, 0, 0, .8) transparent;
	border-width: 8px 8px 0 8px;
	bottom: 1em;
	content: "";
	display: block;
	left: 50%;
	top: 100%;
	position: absolute;
	z-index: 99;
	-webkit-transform: translate(-50%, 0);
	transform: translate(-50%, 0);
}




.search-bar
    margin 0 0 1.5em 0
    .form-group
        margin 0 0.5em
    button
        margin 0 0.5em
    .tags-input
        width 30em

.preserve-newlines
    white-space pre-wrap



.break-word
    word-break break-word

.caret-right
    display inline-block
    width 0
    height 0
    margin-left 2px
    vertical-align middle
    border-left 4px solid
    border-top 4px solid transparent
    border-bottom 4px solid transparent

.caret-left
    display inline-block
    width 0
    height 0
    margin-right 2px
    vertical-align middle
    border-right 4px solid
    border-top 4px solid transparent
    border-bottom 4px solid transparent

.comments-container
    .comments-form
        margin-bottom 10px

        .uploads-row
            margin-top 10px

        .comment-input-group
            margin-bottom 13px

    .comment-entry
        &:first-of-type
            padding-top 20px

        .comment-body
            padding 12px 12px
            border 2px solid #CFE1E2
            border-radius 3px
            background-color #EAFBFC

            p.comment
                white-space pre-line

            &.private
                background-color #fcf0ba
                border-color #F1E9C5

            .uploads-container
                margin-top 20px

.icon-space
    margin-right .3em
    text-align left

.icon-space-right
    margin-left .3em
    text-align right

.bottom-margin
    margin-bottom 1em

.fake-button
    color #333333
    background-color #ffffff
    border-color #cccccc
    display inline-block
    padding 6px 12px
    margin-bottom 0
    font-size 14px
    font-weight 400
    line-height 1.42857143
    vertical-align middle
    border 1px solid #cccccc
    border-radius 4px
    background-image none
    text-align center

.alert-inline
    margin-top: 15px;
    margin-bottom: 0;

ul.form-list
    padding-left: 15px;
    padding-top: 15px;

$calendar-general-color = #666
$calendar-required-color = #d80000
$calendar-ready-color = #ff8400
$calendar-scheduled-color = #008C00
$calendar-supported-color = #4c87c6

.text-calendar-general
    color: $calendar-general-color

.text-calendar-required
    color: $calendar-required-color

.text-calendar-ready
    color: $calendar-ready-color

.text-calendar-scheduled
    color: $calendar-scheduled-color

.text-calendar-supported
    color: $calendar-supported-color

// Enforce scrollbar to always be visible (OS X, Chrome)
::-webkit-scrollbar
    -webkit-appearance: none;

::-webkit-scrollbar:vertical {
    width: 7px;
}

::-webkit-scrollbar:horizontal {
    height: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.table>thead>tr>th

table.table
    border-color primaryColor
    color blackColor
    th
        font-family: GalanoGrotesque, sans-serif;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
    tr
        td
            vertical-align middle
            border-top 1px solid primaryColorActive
        th
            border-bottom 2px solid primaryColor

.navbar-nav.navbar-right
    margin-top 0

@media (max-width: 1200px)
    .navbar-nav
        &>li
            &>a
                padding-left: 10px;
                padding-right: 10px;

.full-width
    width 100%

.text-ellipse
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

.dark-hr {
    border-top: 1px solid #cecece;
}
@media (max-width: 767px)
    .xs-top-padding
        padding-top: 10px

    .xs-bottom-padding
        padding-bottom: 10px

.typeahead-right-container > .dropdown-menu
      left: auto !important;
      right: 35px;

.no-blank-image
    position: relative

    img
        &:before
            content: ' '
            display: block
            position: absolute
            height: 100%
            width: 100%
            opacity: 0.7
            background-image:url('../img/broken-image.png')
            background-size: contain
            background-repeat: no-repeat
            background-position: center

.checkbox
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

.textarea-only-vertical-resize
    resize: vertical;

.btn-group-flex
    display: flex;

.btn .caret.caret-right-align
    position absolute
    top 50%
    right 12px
    margin-top: -2px
    vertical-align: middle

$required-color = #d80000

.field-required
    border 1px solid $required-color
    outline 0
    -webkit-box-shadow inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(241,47,67,.6)
    box-shadow inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(241,47,67,.6)

.required, .error
    color $required-color

.auth-stripe
    margin-top 20px
    margin-bottom 10px
    max-height 80px
    max-width 220px    
